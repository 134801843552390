import React, { Component, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { sendData } from '../../pages/api/sendData';
import { string as yupString, object as yupObject } from 'yup';

const Formik = require('formik').Formik;
const Field = require('formik').Field;

// Dynamic imports
const InputField = dynamic(() => import('../InputField/index'));
const Button = dynamic(() => import('../Button/Button'));
const Arrow = dynamic(() => import('../../../public/assets/arrows/smallarrow.js'));

//Form validation
const validation = yupObject().shape({
  name: yupString().required('Name is required'),
  email: yupString()
  .email()
  .trim()
  .required(),
});

const Form = ({ border }) => {
  const [submitted, setSubmit] = useState(null);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      email: '',
      optin: true,
    },
    onSubmit: values => {
      setSubmit(true);
      sendData(values)
    },
  });

  const s = require('./form.module.scss');
  return (
    <div className={s.FormContainer}>
      <div className={s.FormWrap}>
        {!submitted ? (
          <div className={s.FormContent}>
            <h1>Subscribe to our Newsletter:</h1>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit} className={s.RegistrationForm}>
                  <div className={s.FormFrame}>
                    <div className={s.Field}>
                      <Field name="first_name" id="first_name" label="Name" component={InputField} border={border} type="text" autoComplete="none" errors={formik.errors && formik.errors.Name} touched={formik.touched && formik.touched.Name} />
                    </div>
                    <div className={s.Field}>
                      <Field name="email" id="email" label="E-Mail" component={InputField} border={border} type="text" autoComplete="none" errors={formik.errors && formik.errors.Email} touched={formik.touched && formik.touched.Email} />
                    </div>
                  </div>
                  <div className={s.Well}>
                    <Button type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <span>Subscribing...</span> : <span>Subscribe<Arrow /></span>}
                    </Button>
                  </div>
                </form>
              </FormikProvider>
            </div>
          ) : (
            <div className={s.form_wrapper}>
              <h1>Thank you for subscribing</h1>
            </div>
          )}
      </div>
    </div>
  );
}

Form.propTypes = {
  border: PropTypes.bool,
};

export default Form;